import json from "../../assets/data.json";
import { Params } from "../models";

export default function Home(params: Params) {
  const ret = document.createElement("div");
  ret.classList.add("grid");
  let html = "";

  let ausgaben = json.hertzschlag;
  if (params.embed) {
    ausgaben = ausgaben.reverse().slice(0, 10);
  }

  ausgaben.forEach((i) => {
    html += `
      <a class="module" href="/#hertzschlag/${i.Ausgabe}" target="_top">
          <img src="${json.baseUrl}hertzschlag/images/${i.Ausgabe}.jpg" class="moduleImg"></img>
          <div class="moduleLabel">
              <h3>HS ${i.Ausgabe}</h3>
              <h2>${i.Thema}</h2>
          </div>
      </a>`;
  });

  ret.innerHTML = html;

  return ret;
}
